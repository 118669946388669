<template lang="pug">
.profile-search.container
    .form-floating(style="margin-bottom:15px")
        input.form-control(placeholder="search" v-model="queryStr" v-on:input="onQueryChange")
        label 
            span.material-icons search
            | {{ $t("message.search") }}
    .profiles.row.text-start
        .profile.col-6.col-md-4(v-for="p in listProfiles" style="padding:15px" :key="p._id")
            ProfileCard(:id="p._id" :key="p._id")
</template>

<script>
import API from "./../API.js";
import ProfileCard from "./../components/ProfileCard.vue"
export default {
    data(){
        return {
            listProfiles: [],
            queryStr: "",
            timer: 0,
        }
    },
    components:{
        ProfileCard
    },
    methods:{
        onQueryChange(){
            if(this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                API.searchProfiles(this.queryStr).then((data)=>{
                    this.listProfiles = data.profiles;
                });
            }, 300)
        }
    },
    mounted(){
        API.searchProfiles().then((data)=>{
            this.listProfiles = data.profiles;
        });
    }
}
</script>

<style>

</style>